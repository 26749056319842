import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import { useContext, useEffect, useState } from "react";
import { MainContainer } from "../styled/templates/MainContainer";
import { useMediaQuery } from "@mui/material";
import { auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { MainPage } from "../styled/templates/MainPage";
import CircularLoader from "../styled/loaders/CircularLoader";
import VerificationSettings from "./inners/settings/VerificationSettings";
import Verify from "../components/Verify";
import Header from "../components/Header";
import { ThemedPage } from "../styled/templates/ThemedPage";
import { context } from "../context/context";

const Verification = () => {
  const mobile = useMediaQuery("(max-width: 768px)");

  const [sidebarHidden, setSidebarHidden] = useState(mobile ? true : false);

  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(true);

  const { userData } = useContext(context);

  useEffect(() => {
    if (!loading && user) {
      setLoader(false);
    }

    if (loading && !user) {
      setLoader(true);
    }

    if (!loading && !user) {
      navigate("/login");
    }

    if (!loading && user && userData.verified) {
      setLoader(false);
      navigate("/dashboard");
      // console.log("user already logged in!");
    }
  }, [user, loading, userData]);

  return (
    <ThemedPage
      className="scrollbar-hide"
      style={{ overflow: "scroll", height: "100vh" }}
    >
      {loader ? (
        <div className="container scrollbar-hide" style={{ display: "grid" }}>
          <CircularLoader
            bg="rgba(12, 108, 243, 0.2)"
            size="44"
            color="#0C6CF2"
          />
          {/* <p style={{ placeSelf: "center" }}>loader...</p> */}
        </div>
      ) : (
        //   <h1 style={{ color: "white" }}>Verify your account</h1>
        //   <Verify />
        // </div>

        <div
          className="main"
          style={{
            padding: "32px",
            margin: "0 auto",
            maxWidth: "700px",
            // maxHeight: "80vh",
            // overflow: "scroll",
            paddingTop: "120px",
          }}
        >
          <div
            className="login__form__standard"
            style={{
              padding: "0px",
              margin: "0 auto",
              display: "block",
              // paddingTop: "120px",
              maxWidth: "unset",
            }}
          >
            <h1 style={{ color: "white" }}>Verify your account</h1>
          </div>
          <Verify />
        </div>
      )}
    </ThemedPage>
  );
};

export default Verification;
